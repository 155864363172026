/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ph {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
    height: 60vh;
}