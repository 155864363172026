.navbar {
    background-color: #f2f2f2;
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar .logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .navbar .menu {
    display: flex;
    list-style: none;
  }
  
  .navbar .menu li {
    margin-right: 10px;
  }
  
  .navbar .menu li a {
    color: #666;
    text-decoration: none;
  }
  
  .navbar .menu li a:hover {
    color: #333;
  }
  
  .navbar .hamburger {
    display: none; /* hide hamburger button by default */
  }
  
  @media (max-width: 768px) {
    .navbar .menu {
      display: none; /* hide menu items on small screens */
    }
  
    .navbar .hamburger {
      display: block; /* show hamburger button on small screens */
      font-size: 24px;
      color: #333;
      cursor: pointer;
    }
  
    .navbar .hamburger:hover {
      color: #666;
    }
  }
  